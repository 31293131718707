
$(window).on('load resize orientationchange', function() {
	slideSet();
	slideClinicSet();
	slideGuideSet();
	slideGallerySet();
	slideFacilitySet();
	slideSurgerySet();

	slideFooterBanerSet();
});

// top slide
function slideSet() {
	
		$('.js-topSlide').not('.slick-initialized').slick({
			arrows: true,
			autoplay: true,
			infinite: true,
			touchThreshold: 15,
			slidesToShow: 1,
			centerMode: false,
			autoplaySpeed: 5000,
			speed: 800,
			centerPadding: '0%',
			prevArrow: '<button class="uq_top-mv__slide__arrow uq_top-mv__slide__arrow_prev"></button>',
      nextArrow: '<button class="uq_top-mv__slide__arrow uq_top-mv__slide__arrow_next"></button>',
			dots: true,
			customPaging: function(slick, index){
				var num = slick.$slides.eq(index).html();
				return '<div class="slick-dots__dot">' + '</div>';
			},
			responsive: [
			{
				breakpoint: 750,
				settings: {
					centerPadding: '5.5vw'
				}
			}
			]
		});
}

function slideFooterBanerSet() {
	$('.js-topBanerSlide').not('.slick-initialized').slick({
		autoplay: true, //自動再生
		autoplaySpeed: 3000, //autoplayスピード
		infinite: true, //スライドのループ有効化
		dots: true, //ドットのナビゲーションを表示
		slidesToShow: 4, //表示するスライドの数
		slidesToScroll: 1, //スクロールで切り替わるスライドの数
		centerMode: false,
		dots: true,
		arrows: true,
		prevArrow: '<button class="m_slick__arrow m_slick__arrow_prev"></button>',
    nextArrow: '<button class="m_slick__arrow m_slick__arrow_next"></button>',
		customPaging: function(slick, index){
			var num = slick.$slides.eq(index).html();
			return '<div class="slick-dots__dot">' + '</div>';
		},
		responsive: [{
			breakpoint: 750,
			settings: {
				slidesToShow: 1,
				centerPadding: '5.5vw',
			}
		}]
	});
}

function slideGuideSet() {
	$('.js-guide_Slide').not('.slick-initialized').slick({
		autoplay: false, //自動再生
		autoplaySpeed: 3000, //autoplayスピード
		infinite: true, //スライドのループ有効化
		dots: false, //ドットのナビゲーションを表示
		slidesToShow: 1, //表示するスライドの数
		slidesToScroll: 1, //スクロールで切り替わるスライドの数
		centerMode: false,
		arrows: false,
		asNavFor: '.js-guide_nav_Slide', //サムネイルのクラス名
		responsive: [{
			breakpoint: 750,
			settings: {
				slidesToShow: 1,
				centerPadding: '5.5vw',
				adaptiveHeight: true,
			}
		}]
	});
	var slidesToshow_val = 5;
	var slidesToshow_leng = $('.js-guide_nav_Slide .up_clinic-guide__slide--nav__item').length;
		if(slidesToshow_leng < slidesToshow_val){
			slidesToshow_val = slidesToshow_leng;
		}

	$('.js-guide_nav_Slide').not('.slick-initialized').slick({
		infinite: true,
		slidesToShow: slidesToshow_val,
		slidesToScroll: 1,
		asNavFor: '.js-guide_Slide', //スライダー本体のクラス名
		dots: false, //ドットのナビゲーションを表示
		focusOnSelect: true,
		arrows: true,
		centerMode: true,
		prevArrow: '<button class="m_slick__arrow m_slick__arrow_prev"></button>',
		nextArrow: '<button class="m_slick__arrow m_slick__arrow_next"></button>',
		responsive: [{
			breakpoint: 750,
			settings: {
				//slidesToShow: 1,
				centerPadding: '15.5vw',
			}
		}]
	});
}

//施設案内
function slideGallerySet() {
	$('.js-gallery_Slide').not('.slick-initialized').slick({
		autoplay: false, //自動再生
		autoplaySpeed: 3000, //autoplayスピード
		infinite: true, //スライドのループ有効化
		dots: false, //ドットのナビゲーションを表示
		slidesToShow: 1, //表示するスライドの数
		slidesToScroll: 1, //スクロールで切り替わるスライドの数
		centerMode: false,
		arrows: false,
		asNavFor: '.js-gallery_nav_Slide', //サムネイルのクラス名
		responsive: [{
			breakpoint: 750,
			settings: {
				slidesToShow: 1,
				centerPadding: '5.5vw',
				infinite: false, //スライドのループ有効化
				focusOnSelect: false,
				adaptiveHeight: true,
			}
		}]
	});
	var slidesGalleryToshow_val = 5;
	var slidesGalleryToshow_leng = $('.js-gallery_nav_Slide .uq_clinic-gallery__slide--nav__item').length;
		if(slidesGalleryToshow_leng < slidesGalleryToshow_val){
			slidesGalleryToshow_val = slidesGalleryToshow_leng;
		}
	$('.js-gallery_nav_Slide').not('.slick-initialized').slick({
		infinite: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		asNavFor: '.js-gallery_Slide', //スライダー本体のクラス名
		dots: false, //ドットのナビゲーションを表示
		focusOnSelect: true,
		arrows: false,
		centerMode: false,
		responsive: [{
			breakpoint: 750,
			settings: {
				slidesToShow: 5,
				centerPadding: '5.5vw',
				infinite: false, //スライドのループ有効化
				focusOnSelect: false,
			}
		}]
	});
}


//施設案内
function slideFacilitySet() {
	$('.js-facility_Slide').not('.slick-initialized').slick({
		autoplay: false, //自動再生
		autoplaySpeed: 3000, //autoplayスピード
		infinite: true, //スライドのループ有効化
		dots: false, //ドットのナビゲーションを表示
		slidesToShow: 1, //表示するスライドの数
		slidesToScroll: 1, //スクロールで切り替わるスライドの数
		centerMode: false,
		arrows: false,
		asNavFor: '.js-facility_nav_Slide', //サムネイルのクラス名
		responsive: [{
			breakpoint: 750,
			settings: {
				slidesToShow: 1,
				centerPadding: '5.5vw',
				infinite: false, //スライドのループ有効化
				focusOnSelect: false,
				adaptiveHeight: true,
			}
		}]
	});
	var slidesfacilityToshow_val = 5;
	var slidesfacilityToshow_leng = $('.js-facility_nav_Slide .uq_clinic-gallery__slide--nav__item').length;
		if(slidesfacilityToshow_leng < slidesfacilityToshow_val){
			slidesfacilityToshow_val = slidesfacilityToshow_leng;
		}
	$('.js-facility_nav_Slide').not('.slick-initialized').slick({
		infinite: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		asNavFor: '.js-facility_Slide', //スライダー本体のクラス名
		dots: false, //ドットのナビゲーションを表示
		focusOnSelect: true,
		arrows: false,
		centerMode: false,
		responsive: [{
			breakpoint: 750,
			settings: {
				slidesToShow: 5,
				centerPadding: '5.5vw',
				infinite: false, //スライドのループ有効化
				focusOnSelect: false,
			}
		}]
	});
}



// Clinic Mv slide
function slideClinicSet() {
	$('.js-clinicSlide').not('.slick-initialized').slick({
		autoplay: true, //自動再生
		autoplaySpeed: 3000, //autoplayスピード
		infinite: true, //スライドのループ有効化
		dots: true, //ドットのナビゲーションを表示
		slidesToShow: 1, //表示するスライドの数
		slidesToScroll: 1, //スクロールで切り替わるスライドの数
		centerMode: true,
		centerPadding: '17%',
		prevArrow: '<button class="uq_clinic-mv__slide__arrow uq_clinic-mv__slide__arrow_prev"></button>',
		nextArrow: '<button class="uq_clinic-mv__slide__arrow uq_clinic-mv__slide__arrow_next"></button>',
		dots: true,
		customPaging: function(slick, index){
			var num = slick.$slides.eq(index).html();
			return '<div class="slick-dots__dot">' + '</div>';
		},
		responsive: [
		{
			breakpoint: 750,
			settings: {
				centerPadding: '0.5vw'
			}
		}
		]
	});
}
// single slide
$(function(){
	$('.js-slide').slick({
		arrows: false,
		autoplay: true,
		infinite: true,
		touchThreshold: 15,
		slidesToShow: 1,
		autoplaySpeed: 2500,
		pauseOnFocus: false,
		pauseOnHover: false,
		speed: 800,
		dots: true,
		customPaging: function(slick, index){
			var num = slick.$slides.eq(index).html();
			return '<div class="slick-dots__dot">' + '</div>';
		}
	});
});

function slideSurgerySet() {
	if(winW <= 756) {
		$('.js_surgery--slider').not('.slick-initialized').slick({
			autoplay: true, //自動再生
			autoplaySpeed: 3000, //autoplayスピード
			infinite: true, //スライドのループ有効化
			dots: true, //ドットのナビゲーションを表示s
			slidesToShow: 1, //表示するスライドの数
			slidesToScroll: 1, //スクロールで切り替わるスライドの数
			centerMode: true,
			centerPadding: '30%',
			prevArrow: '<button class="uq_operation--surgery--slider__arrow uq_operation--surgery--slider__arrow_prev"></button>',
      nextArrow: '<button class="uq_operation--surgery--slider__arrow uq_operation--surgery--slider__arrow_next"></button>',
			customPaging: function(slick, index){
				var num = slick.$slides.eq(index).html();
				return '<div class="slick-dots__dot">' + '</div>';
			},
			responsive: [
			{
				breakpoint: 750,
				settings: {
					centerPadding: '7.5vw'
				}
			}
			]
		});
	} else {
		$('.js_surgery--slider.slick-initialized').slick('unslick');
	}
}